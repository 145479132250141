<template>
  <div class="s-orders-page">
    <Tab />
    <p v-if="fetching"><Loading /></p>
    <template v-else-if="orders.length">
      <ul class="s-mb-6">
        <li v-for="(order, i) in orders" :key="i">
          <Item :data="order" :status="status" />
        </li>
      </ul>
      <div class="s-orders-notify s-mb-4">
        <p class="s-text-gray s-text-sm">訂單小幫手每 3 分鐘會更新一次訂單狀態唷</p>
      </div>
      <Pagination :current="page" :last="lastPage" />
    </template>
    <div class="s-pt-40 s-text-center" v-else>
      <img class="s-mb-3" src="@/assets/images/liff/empty.svg">
      <p class="s-text-gray">沒有團購</p>
      <p class="s-text-gray">訂單小幫手每 3 分鐘會更新一次訂單狀態唷</p>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import Loading from '@/components/Page/Liff/Shared/Loading'
  import Tab from '@/components/Page/Liff/Orders/Tab'
  import Item from '@/components/Page/Liff/Orders/Item'
  import Pagination from '@/components/Page/Liff/Orders/Pagination'
  import withFetcher from '@/mixins/liff/withFetcher'

  export default {
    mixins: [withFetcher],
    components: { Loading, Tab, Item, Pagination },
    inject: ['$alert'],
    data: () => ({
      orders: [],
      lastPage: 1,
    }),
    computed: {
      status () {
        return this.$route.params.status
      },
      page () {
        return +this.$route.query.page || 1
      },
    },
    watch: {
      status: '$fetch',
      page: '$fetch',
    },
    async created () {
      if (!this.status) {
        await this.$router.replace({
          name: 'LiffOrdersStatus',
          params: { status: 'processing' },
        })
      }
    },
    methods: {
      ...mapActions({
        index: 'liffOrder/index',
      }),
      async fetch () {
        if (!this.status) {
          await new Promise(() => {})
        }
        this.orders = []
        this.lastPage = 1
        const { data, lastPage } = await this.index(
          { status: this.status, page: this.page }
          )
        this.orders = data
        this.lastPage = lastPage
      }
    },
  }
</script>

<style scoped>
  .s-orders-page {
    margin-top: -1.5rem;
  }

  .s-orders-tab {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    margin-left: -1rem;
    margin-right: -1rem;
    margin-bottom: 0.5rem;
  }

  .s-orders-notify {
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--s-white);
    padding: 1rem 0;
    text-align: center;
    z-index: 1;
  }
</style>
